import React from 'react'
import './float.scss'

function Float({right, top, color, duration}) {
  return (
    <div style={{"right": right, "top": top, "background": color, "animationDuration": duration}} className="floating">
    </div>
  )
}

export default Float
