import React, { Fragment, useEffect } from "react"
import { connect } from "react-redux"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { setQuote } from "../redux/actions/quoteAction"
import Floating from '../components/floating/float';
import './index.scss'

const IndexPage = ({quote, dispatch}) => {
  useEffect(() => {
    if (!quote) {
      getQuote(dispatch)
    }
  }, [quote, dispatch]);

  const getQuote = (dispatch) => {
    fetch('https://quotes.rest/qod?language=en')
    .then(result => result.json())
    .then(result => {
      dispatch(setQuote(result.contents.quotes[0]))
    })
    .catch(err => console.err(err));
  }

  const renderFloat = () => {
    const style = [
      {right:"20%", top:"25%", color:"red", duration:"1.5s"},
      {right:"85%", top:"40%", color:"blue", duration:"2.5s"},
      {right:"35%", top:"65%", color:"green", duration:"4.5s"},
      {right:"70%", top:"75%", color:"yellow", duration:"3s"},
    ]
    return style.map((data, index) => (
      <Floating key={index} right={data.right} top={data.top} color={data.color} duration={data.duration}/>
    ))
  }
  
  const renderQuote = (quote) => {
    if (quote) {
      return (
      <div>
        <h2 className="quote-text text--bold mb--0">{quote.quote}</h2>
        <h3 className="quote-author text--italic mt--0">{quote.author}</h3>
      </div>
      )
    } else {
      return (
        <Fragment>
          <p>
            ...
          </p>
        </Fragment>
      )
    }
  }
  return (
    <Layout>
      <SEO title="Welcome Stranger"/>
      {renderFloat()}
      <div className="home-wrapper">
        <div className="intro-wrapper">
          <div className="greeting">
            <h1 className="text--bold m--0 mr--0-5 ">HELLO</h1>
            <div className="greeting-border"></div>
          </div>
          <h1 className="text--bolder mt--0-5 text--size-40">I AM <span className="color-main">ANDREW</span></h1>
          <h2 className="m--0 text--normal">Front-end Developer</h2>
        </div>
        <div className="quote-wrapper">
          {renderQuote(quote)}
        </div>
      </div>
    </Layout>
  )
}

export default connect(state => ({
  quote: state.quoteData.quote
}), null)(IndexPage)
